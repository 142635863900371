.checkout-form {
  background-color: white;
  border-radius: 10px;
}

.checkout-forms {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-in-out;
  border-color: var(--wp--preset--color--light-gray) !important;
}

.checkout-form label {
  display: block;
  margin-bottom: 10px;
}

.checkout-form input,
.checkout-form select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.card-element-wrapper {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-bottom: 20px;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #1C7DFB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #34506D;
}

/* .paymentHeading{
  font-size: 30px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
} */

.paymentHeading {
  font-size: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
  background: #2d81e2;
  color: #fff;
  font-weight: bold;
}


/* @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  } */