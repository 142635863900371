.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url('../img/form-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20%;

}

/* .image-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
} */


.form-column img {
  width: 40%;
  display: block;
  margin: auto;
  margin-top: -20px;
  margin-bottom: 10px;
}

.form-column {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  padding: 20px;
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
} */